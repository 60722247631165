import moment from "moment";
import { Modal, Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { FaRegCopy } from "react-icons/fa";
import NoData from '../../../../common/noData';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import InfoIcon from '../../../../common/Icons/InfoIcon';
import ThreeDots from '../../../../../assets/image/icons/ThreeDots.png'
import CustomTooltip from "../../../../common/CustomTooltip/CustomTooltip";
import { weightGreater } from '../../../../../customFunction/functionLogic'
import ForwardIcon from '../../../../../assets/image/icons/ForwardIcon.png'
import VerifiedOrderIcon from "../../../../common/Icons/VerifiedOrderIcon";
import CallingDetailsIcon from "../../../../common/Icons/CallingDetailsIcon";
import { customErrorFunction } from "../../../../../customFunction/errorHandling";
import Cookies from 'js-cookie';
import { BASE_URL_COURIER } from "../../../../../axios/config";
import axios from "axios";


const ActionRequired = ({ selectAll, setSelectAll, shipmentCard, selectedRows, setSelectedRows, setBulkActionShow, setAwbNo, setOrderTracking, partnerList }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [allShipment, setAllShipment] = useState([]);
    const [selectedData, setSelectedData] = useState(null);
    const [copyText, setcopyText] = useState("Tracking Link")
    const channel_list = JSON.parse(localStorage.getItem('channel_list'));


    useEffect(() => {
        if (shipmentCard) {
            setAllShipment(shipmentCard);
        }
    }, [shipmentCard]);

    const handleReattempt = ((orderIds) => {
        const updatedRules = allShipment.filter(shipment => shipment.id !== orderIds);
        setAllShipment(updatedRules);
        const stringifiedOrderIds = JSON.stringify(orderIds);
        dispatch({ type: "SHIPMENT_REATTEMPT_DATA_ACTION", payload: { "order_ids": stringifiedOrderIds } });
    });

    const handleRto = ((orderIds) => {
        const updatedRules = allShipment.filter(shipment => shipment.id !== orderIds);
        setAllShipment(updatedRules);
        const stringifiedReattempt = JSON.stringify(orderIds);
        dispatch({ type: "SHIPMENT_RTO_DATA_ACTION", payload: { "order_ids": stringifiedReattempt } });
    });

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        if (!selectAll) {
            setSelectedRows(shipmentCard.map(row => row?.id));
            setBulkActionShow(true)
        } else {
            setSelectedRows([]);
            setBulkActionShow(false)
        }
    };

    const handleSelectRow = (orderId) => {
        const isSelected = selectedRows.includes(orderId);

        if (isSelected) {
            setSelectedRows(selectedRows.filter(id => id !== orderId));
            setBulkActionShow(true)
        } else {
            setSelectedRows([...selectedRows, orderId]);
        }
        if (setSelectedRows !== ([])) {
            setBulkActionShow(true)
        }
        if (selectedRows.length === allShipment?.length - 1 && isSelected) {
            setSelectAll(false);
        } else {
            setSelectAll(false);
        }
    };

    const handleShow = (row) => {
        setSelectedData(row);
        setShow(true);
    };

    const handleClose = () => setShow(false);

    const handleClickAWB = (orders) => {
        setOrderTracking(true)
        setAwbNo(orders)
    };

    const handleClickpartner = (event, row) => {
        event.preventDefault();
        const courierPartner = row?.courier_partner.toLowerCase();

        switch (courierPartner) {
            case "bluedart":
                window.open('https://www.bluedart.com/web/guest/home', '_blank');
                break;
            case "delhivery":
                window.open('https://www.delhivery.com/track/package', '_blank');
                break;
            case "smartr":
                window.open('https://smartr.in/tracking', '_blank');
                break;
            case "ekart":
            case "ekart_5kg":
                window.open('https://ekartlogistics.com/', '_blank');
                break;
            case "shadowfax":
                window.open('https://tracker.shadowfax.in/#/', '_blank');
                break;
            case "amazon_swa":
                window.open('https://track.amazon.in/', '_blank');
                break;
            case "xpressbees":
                window.open('https://www.xpressbees.com/shipment/tracking', '_blank');
                break;
            case "shree maruti":
                window.open('https://www.shreemaruti.com/', '_blank');
                break;
            case "movin":
                window.open('https://www.movin.in/shipment/track', '_blank');
                break;
            case "ecom express":
                window.open('https://ecomexpress.in/tracking/', '_blank');
                break;
            case "professional":
                window.open('https://www.tpcindia.com/Default.aspx', '_blank');
                break;
            default:
                break;
        }
    }

    const handleCopy = (awb) => {
        const temp_url = `https://shipease.in/order-tracking/${awb}`
        navigator.clipboard.writeText(temp_url)
            .then(() => {
                setcopyText("Copied")
                setTimeout(() => {
                    setcopyText('Tracking Link');
                }, 2000);
            })
            .catch(err => {
            });
    };

    const handleEscalate = (awbNumber) => {
        navigate(`/customer-support?awb_number=${awbNumber}`);
    }

    const [ShowCallDetails, setShowCallDetails] = useState(null)
    const authToken = Cookies.get("access_token")
    const [callData, setCallData] = useState([])

    const handleCallingDetails = async (id) => {
        setShowCallDetails(!ShowCallDetails)
        try {
            if (id != null) {
                const response = await axios.get(`${BASE_URL_COURIER}/courier-api/master/ndr-calling/?awb_number=${id}`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                });
                setCallData(response?.data)
            }
        } catch (error) {
            customErrorFunction(error)
        }

    }



    return (
        <>
            <section className='position-relative'>
                <div className="position-relative">
                    <div className='table-container'>
                        <table className=" w-100">
                            <thead className="sticky-header">
                                <tr className="table-row box-shadow">
                                    <th style={{ width: '1%' }}>
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                        />
                                    </th>
                                    <th>Order Details</th>
                                    <th>NDR Attempt</th>
                                    <th>Package Details</th>
                                    <th>Customer details</th>
                                    <th>Tracking Detail</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                <tr className="blank-row"><td></td></tr>
                            </thead>
                            <tbody>
                                {allShipment?.map((row, index) => (
                                    <React.Fragment key={row?.id}>
                                        {index > 0 && <tr className="blank-row"><td></td></tr>}
                                        <tr className='table-row box-shadow'>
                                            <td className='checkbox-cell'>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedRows.includes(row?.id)}
                                                    onChange={() => handleSelectRow(row?.id)}
                                                />
                                            </td>
                                            <td>
                                                {/* Date detail */}
                                                <div className='cell-inside-box'>
                                                    <p className=''>
                                                        {row?.channel && <img src={channel_list[row?.channel]["image"]} alt="channel" width="20" />}
                                                        <span className='d-inline-flex align-items-center gap-1 ms-2'>
                                                            <Link to={`/orderdetail/${row?.id}`} className='anchor-order'>{row?.customer_order_number}</Link>
                                                            {row?.other_details?.is_verified &&
                                                                <CustomTooltip
                                                                    triggerComponent={<VerifiedOrderIcon />}
                                                                    tooltipComponent='Verified'
                                                                    addClassName='verified-hover'
                                                                />
                                                            }
                                                        </span>
                                                        <span onClick={() => handleCallingDetails(row?.awb_number)} className="ms-2 cursor-pointer"><CallingDetailsIcon /></span>
                                                    </p>
                                                    <p className='ws-nowrap d-flex align-items-center'>
                                                        <CustomTooltip
                                                            triggerComponent={
                                                                <img
                                                                    src={ForwardIcon}
                                                                    className={`${row?.order_type === 'Forward' ? '' : 'icon-rotate'}`}
                                                                    alt="Forward/Reverse"
                                                                    width={24}
                                                                />
                                                            }
                                                            tooltipComponent={<>{row?.order_type}</>}
                                                            addClassName='verified-hover'
                                                        />
                                                        <span className='ms-2'>{`${moment(row?.created_at).format('DD MMM YYYY')} || ${moment(row?.created_at).format('h:mm A')}`}</span>
                                                        {row?.is_mps === true &&
                                                            <span className="mps-flag">MPS</span>
                                                        }
                                                        {/* {
                                                        row?.order_tag.length > 0 && <CustomTooltip
                                                            triggerComponent={<span className='ms-1'>
                                                                <OrderTagsIcon />
                                                            </span>}
                                                            tooltipComponent={
                                                                <div className='Labels-pool'>
                                                                    {row?.order_tag?.map((item) => {
                                                                        return (
                                                                            <div className="label-button-container active"><button className='label-button'><FontAwesomeIcon icon={faCircle} className='me-2' />{item.name}</button></div>

                                                                        )
                                                                    })}
                                                                </div>
                                                            }
                                                        />
                                                    } */}
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                {/* NDR Reason*/}
                                                <div className='cell-inside-box'>
                                                    <p ><strong>Attempts: </strong>{row?.ndr_details.length}<span>{" "}</span>
                                                        <InfoIcon onClick={() => handleShow(row)} />
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                {/* package  details */}
                                                <div className='cell-inside-box'>
                                                    <p className='width-eclipse'>{row?.order_products.product_name}</p>
                                                    <p>Wt:  {weightGreater(row?.dimension_detail?.weight, row?.dimension_detail?.vol_weight)} kg
                                                        <span className='details-on-hover ms-2 align-middle'>
                                                            <InfoIcon />
                                                            <span style={{ width: '250px' }}>
                                                                {row?.order_products.map((product, index) => (
                                                                    <React.Fragment key={index}>
                                                                        <strong>Product:</strong> {product.product_name}<br />
                                                                        <strong>SKU:</strong> {product.sku}<br />
                                                                        <strong>Qt.:</strong> {product.quantity}<br />
                                                                    </React.Fragment>
                                                                ))}
                                                            </span>
                                                        </span>
                                                        <br />
                                                        <span>LBH(cm): {row?.dimension_detail?.length} x {row?.dimension_detail?.breadth} x {row?.dimension_detail?.height}</span>
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='cell-inside-box'>
                                                    <p>{row?.shipping_detail?.recipient_name}</p>
                                                    <p>{row?.shipping_detail?.mobile_number ?? null}
                                                        <span className='details-on-hover ms-2'>
                                                            <InfoIcon />
                                                            <span style={{ width: '250px' }}>
                                                                {row?.shipping_detail?.address}, {row?.shipping_detail?.landmark}, {row?.shipping_detail?.city},{row?.shipping_detail?.state}, {row?.shipping_detail?.pincode}
                                                            </span>
                                                        </span>
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='cell-inside-box shipping-details'>
                                                    {row?.courier_partner && <img src={partnerList[row?.courier_partner]["image"]} title='Partner' />}
                                                    <div>
                                                        <p className='details-on-hover anchor-awb' onClick={(e) => handleClickAWB(row?.awb_number)}>
                                                            {row?.awb_number}
                                                        </p>
                                                        <p className='mt-1 cursor-pointer text-capitalize' onClick={(event) => handleClickpartner(event, row)}>
                                                            {row?.courier_partner && partnerList[row?.courier_partner]["title"]}
                                                        </p>
                                                    </div>
                                                    <CustomTooltip
                                                        triggerComponent={<button className='btn copy-button p-0 ps-1' onClick={() => handleCopy(row?.awb_number)}><FaRegCopy /></button>}
                                                        tooltipComponent={copyText}
                                                        addClassName='copytext-tooltip'
                                                    />
                                                </div>
                                            </td>
                                            <td className='align-middle status-box'>
                                                <p className='order-Status-box'>{row?.status.split("_").join(" ")}</p>
                                            </td>
                                            <td className='align-middle'>
                                                <div className='d-flex align-items-center gap-3'>
                                                    <button className='btn main-button' onClick={() => handleReattempt(row?.id)}>Re-Attempt</button>
                                                    <div className='action-options'>
                                                        <div className='threedots-img'>
                                                            <img src={ThreeDots} alt="ThreeDots" width={24} />
                                                        </div>
                                                        <div className='action-list'>
                                                            <ul>
                                                                <li onClick={() => handleRto(row?.id)}>RTO</li>
                                                                <li onClick={() => handleEscalate(row?.awb_number)}>Escalate</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                        {allShipment?.length === 0 && <NoData />}
                    </div>
                    <Preview show={show} handleClose={handleClose} selectedData={selectedData} />
                </div>
            </section>
            <Modal show={ShowCallDetails} onHide={handleCallingDetails} centered className="confirmation-modal calling-details">
                <Modal.Header closeButton>
                    <Modal.Title>Call Details for <b>Order Number</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {callData?.length > 0 ? <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>File Name</th>
                                <th>Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {callData?.map((row, index) => (
                                <tr key={row.id}>
                                    <td>{row.id}</td>
                                    <td>callrec{index + 1}</td>
                                    {`${moment(row?.call_start_time).format('DD MMM YYYY')} || ${moment(row?.call_start_time).format('h:mm A')}`}
                                    <td>
                                        <a
                                            href={row.recording_path}
                                            download
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Download
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table> : "No Recordings found"}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn main-button" onClick={handleCallingDetails}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ActionRequired;

function Preview({ show, handleClose, selectedData }) {
    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>NDR Attempt History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="table">
                    <tbody>
                        <tr>
                            <th>Action Date</th>
                            <th>Action By</th>
                            <th>Reason</th>
                            <th>Remark</th>
                            <th>Status</th>
                        </tr>
                        {selectedData?.other_details?.ndr_reason != null &&
                            <tr>
                                <td>{selectedData?.other_details?.ndr_action_date ? <>{moment(selectedData?.other_details?.ndr_action_date).format("DD MMM YYYY, h:mm A")}</> : "NA"}</td>
                                <td>Courier</td>
                                <td>{selectedData?.other_details?.ndr_reason}</td>
                                <td>{selectedData?.other_details?.ndr_reason}</td>
                                <td>{selectedData?.other_details?.ndr_action}</td>
                            </tr>
                        }
                        {selectedData?.ndr_details?.map((row, index) => (
                            <tr key={index}>
                                <td>{row?.action_date ? <>{moment(row?.action_date).format("DD MMM YYYY, h:mm A")}</> : "NA"}</td>
                                <td>{row?.action_by}</td>
                                <td>{row?.reason}</td>
                                <td className="text-capitalize">{row?.remark}</td>
                                <td className="text-capitalize">{row?.action_status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </Modal.Body>
        </Modal>
    );
}
