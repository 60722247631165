import React from 'react'

const ListCrossIcon = () => {
    return (
        <>
            <svg
                width={24}
                height={24}
                viewBox="0 0 512 512"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_1108_3185)">
                    <path
                        d="M248.5 342.001L374.5 462.501C383.351 471.323 410.402 494.501 422 494.501C433.635 494.501 446.157 483.317 455 474.501L493.228 435.501C513.069 415.66 490.341 395.347 470.5 375.501L354.839 259.89L350.95 256.001L354.839 252.112L479 128.501M252.113 354.84L119 484.001C101.273 501.7 76.6973 505.193 58.9996 487.501L26.9996 458.001C9.30291 440.308 9.30154 407.7 26.9996 390.001L157.157 259.89L161.046 256.001L157.157 252.112L28 118C10.2958 100.272 13.8087 79.6624 31.499 62L58.499 36C76.1673 18.3317 104.302 13.8041 122 31.4997L252.112 157.158L256.001 161.046L259.889 157.158L382 33.0007L389.5 25.5007C407.264 7.79351 435.84 19.3131 453.5 37.0007L482.5 64.5007C500.195 82.2001 496.693 110.809 479 128.501M479 128.501L473 134.474"
                        stroke="#F31429"
                        strokeWidth={23}
                    />
                    <path
                        d="M94.7109 420.682L425 86"
                        stroke="#F31429"
                        strokeWidth={30}
                        strokeLinecap="round"
                    />
                    <path
                        d="M92 92L415 410"
                        stroke="#F31429"
                        strokeWidth={30}
                        strokeLinecap="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1108_3185">
                        <rect width={512} height={512} fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </>
    )
}

export default ListCrossIcon